<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card" class="top_nav">
      <!-- 附加税 -->
      <el-tab-pane label="城市生活垃圾处置费" name="1">
        <div class="right_box">
          <!-- <el-button type="info" @click="handleDownloadVat()" size="small" plain>导出</el-button> -->
          <!-- <el-button size="small" @click="printingNew()" type="primary">打印</el-button> -->
        </div>
        <div class="big_box4">
          <div class="top_title3">
            <h4>城市生活垃圾处置费</h4>
            <h5>一般纳税人、小规模纳税人适用</h5>
            <div class="bottom_font clearfix">
              <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
              <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
              <h6 class="right">
                <p>单位：元</p>
                <p>纳税人识别号：<span>{{taxNo}}</span></p>
              </h6>
            </div>
          </div>
          <table class="content" cellspacing="0">
            <tr class="top_bg">
              <td class="center" style="width:10%">征收项目</td>
              <td class="center" style="width:10%">征收品目</td>
              <td class="center" style="width:10%">征收子目</td>
              <!-- <td class="center">费款所属期起</td>
              <td class="center">费款所属期止</td> -->
              <td class="center" style="width:6%">应缴费基数</td>
              <td class="center" style="width:6%">应缴费基数减除额</td>
              <td class="center" style="width:6%">计费依据</td>
              <td class="center" style="width:6%">征收标准</td>
              <td class="center" style="width:4%">扣除数</td>
              <td class="center" style="width:4%">征收比例</td>
              <td class="center" style="width:6%">本期应纳费额</td>
              <td class="center" style="width:6%">减免费额</td>
              <td class="center" style="width:6%">减免性质</td>
              <td class="center" style="width:6%">本期已缴费额</td>
              <td class="center" style="width:6%">本期应补（退）费额</td>
            </tr>
           
            <tr class="top_bg">
              <td class="center">1</td>
              <td class="center">2</td>
              <td class="center">3</td>
              <!-- <td class="center">4</td>
              <td class="center">5</td> -->
              <td class="center">6</td>
              <td class="center">7</td>
              <td class="center">8</td>
              <td class="center">9</td>
              <td class="center">10</td>
              <td class="center">11</td>
              <td class="center">12</td>
              <td class="center">13</td>
              <td class="center">14</td>
              <td class="center">15</td>
              <td class="center">16</td>
            </tr>

            <tr>
              <!-- <qzf-str-td :disabled="false" :amount.sync="info[0].zsxmDm" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" :amount.sync="info[0].zspmDm" @change="changeList()"></qzf-str-td> -->
              <td><input type="text" v-model="list[0].zsxmDm"></td>
              <td><input type="text" v-model="list[0].zspmDm"></td>
              <td></td>

              <!-- <td></td>
              <td></td> -->
              <qzf-str-td :disabled="false" v-model:amount="list[0].ybzzs" @change="changeList()"></qzf-str-td>
              <td>0</td>
              <qzf-str-td :disabled="false" v-model:amount="list[0].hj" @change="changeList()"></qzf-str-td>
              <!-- <td>{{list[0].sl1}}</td> -->
              <qzf-str-td :disabled="false" v-model:amount="list[0].sl1" @change="changeList()"></qzf-str-td>
              <td>0</td>
              <td>1</td>
              <qzf-str-td :disabled="false" v-model:amount="list[0].bqynsfe" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[0].jme" @change="changeList()"></qzf-str-td>
              <td>
                {{list[0].jmxzDm}}
              </td>
              <td>0</td>
              <qzf-str-td :disabled="false" v-model:amount="list[0].bqybtse" @change="changeList()"></qzf-str-td>
            </tr>


          </table>
        </div>
      </el-tab-pane>
      <!-- 附加税 end -->
    </el-tabs>
    <div class="save_box">
      <el-button type="primary" @click="saveValues()" size="small" plain>保存</el-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
import { report } from "@/api/printSet"

export default {
  name: 'taxCityLife',
  data() {
    return {
      address: "",
      durationOfTaxTime: '',
      activeName: '1',
      name: '1',
      listQuery: {},
      list: [{},{},{},{}],
      fjsJmList: [],
      period: '',
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 公式
    changeList(){

    },
    getList(){
      gsInfo({tableName: 'gs_ljcl'}).then(res=>{
        if(res.data.data.info){
          this.list = res.data.data.info
          this.durationOfTaxTime = res.data.data.time
        }
      })
    },
    saveValues(){
      let param = {
        tableName:'gs_ljcl',
        items:this.list
      }
      gsEdit(param).then(res => {
        if(res.data.msg == "success") {
          this.$qzfMessage("保存成功")
          this.getList()
        }
      })
    },
    // printingNew(){
    //   let param = {
    //     comIds:[this.$store.getters['user/comInfo'].comId],
    //     period:this.$store.getters['user/comInfo'].period,
    //     bookNames:['gs_ljcl']
    //   }
    //   report(param).then(res=>{
    //     if(res.data.msg == "success"){
    //       window.open(res.data.data.url)
    //     }
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 14px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 26px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 26px;
    // padding: 0 6px;
    font-size: 14px;
  }
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
  }
}

.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  z-index:999
}
</style>